// import { createHashRouter } from 'react-router-dom';
import { createBrowserRouter } from 'react-router-dom';

import App from '@/fsd/app/App';
import { AboutAsync } from '@/fsd/pages/About/ui';
import { CartPageCustom } from '@/fsd/pages/Cart/CartPageCustom';
import { CartPageDfOriginalAsync } from '@/fsd/pages/Cart/CartPageDfOriginal';
import { CheckoutPage } from '@/fsd/pages/Cart/CheckoutPage';
import { ContactsPage } from '@/fsd/pages/ContactsPage';
import { CooperationAsync } from '@/fsd/pages/Cooperation';
import { DeliveryAndPaymentAsync } from '@/fsd/pages/DeliveryAndPayment';
import { KeyCRMDataPage } from '@/fsd/pages/KeyCRMDataPage';
import { OfferAsync } from '@/fsd/pages/Oferta/ui';
import { OrderPaymentMonoAsync } from '@/fsd/pages/order/OrderPaymentMono';
import { ThankYouAsync } from '@/fsd/pages/order/ThankYou/ui/ThankYou.async';
import { ProductPage } from '@/fsd/pages/ProductPage';
import { ProductsPage } from '@/fsd/pages/ProductsPage';

import { ErrorElement } from './ErrorElement';
import { RoutesNames } from './RoutesName';

export const BuildRoutes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorElement message='App' />,
    children: [
      {
        index: true,
        element: <ProductsPage />,
        errorElement: <ErrorElement message='ProductsPage' />,
      },
      {
        path: '/discounts',
        element: <ErrorElement message='Такої сторінки не існує' />,
        errorElement: <ErrorElement message='ProductsPage' />,
      },
      {
        path: RoutesNames.Checkout,
        element: <CheckoutPage />,
        errorElement: <ErrorElement message='ProductsPage' />,
      },
      {
        path: RoutesNames.ProductPage + ':id',
        element: <ProductPage />,
        errorElement: <ErrorElement message='ProductPage' />,
      },
      // {
      //     path: 'product_by_react_router/:id',
      //     element: <ProductPage />,
      //     loader: async ({ request, params }) => {
      //         // loaders can be async functions
      //         const res = await fetch(
      //             '/backend/db/keyCRM/products.json',
      //             {
      //                 signal: request.signal,
      //             }
      //         );
      //         const resJson = await res.json();
      //         const products = fromJSON(resJson.data);
      //         const product = products.find((i: TypeProduct) =>
      //             params.id ? i.id === +params.id : undefined
      //         );

      //         return product ? product : null; // loader should return object or null
      //     },
      //     errorElement: <ErrorElement message='ProductPage' />,
      // },
      {
        path: RoutesNames.KeyCRM,
        element: <KeyCRMDataPage />,
        errorElement: <ErrorElement message='KeyCRMData' />,
      },
      {
        path: RoutesNames.Cart,
        element: <CartPageDfOriginalAsync />,
        errorElement: <ErrorElement message='Cart Page' />,
      },
      {
        path: RoutesNames.Order.ID + '/' + ':orderId',
        element: <OrderPaymentMonoAsync />,
        errorElement: (
          <ErrorElement message='Сторінка підтвердження створення замовлення в CRM завантажена з помилкою' />
        ),
        // children: [
        //   {
        //     path: RoutesNames.Order.Payment,
        //     element: <OrderPaymentMonoAsync />,
        //     errorElement: (
        //       <ErrorElement message='Сторінка вибору оплати завантажена з помилкою' />
        //     ),
        //   },
        //   {
        //     path: RoutesNames.Order.ThankYou,
        //     element: <ThankYouAsync />,
        //     errorElement: (
        //       <ErrorElement message='Сторінка "Дякуємо за замовлення" завантажена з помилкою' />
        //     ),
        //   },
        // ],
      },
      {
        path: '/order/:orderId/payment',
        element: <OrderPaymentMonoAsync />,
        errorElement: (
          <ErrorElement message='Сторінка вибору оплати завантажена з помилкою' />
        ),
      },
      {
        path: '/order/:orderId/thank-you', //RoutesNames.Order.ThankYou,
        element: <ThankYouAsync />,
        errorElement: (
          <ErrorElement message='Сторінка "Дякуємо за замовлення" завантажена з помилкою' />
        ),
      },
      {
        path: '/cart-old',
        element: <CartPageCustom />,
        errorElement: <ErrorElement message='Cart Page Custom' />,
      },
      {
        path: RoutesNames.Info.Contacts,
        element: <ContactsPage />,
        errorElement: <ErrorElement message='Contacts Page' />,
      },
      {
        path: RoutesNames.Info.Oferta,
        element: <OfferAsync />,
        errorElement: <ErrorElement message='Offer Page' />,
      },
      {
        path: RoutesNames.Info.About,
        element: <AboutAsync />,
        errorElement: <ErrorElement message='About Page' />,
      },
      {
        path: RoutesNames.Info.DeliveryAndPayment,
        element: <DeliveryAndPaymentAsync />,
        errorElement: <ErrorElement message='Delivery And Payment Page' />,
      },
      {
        path: RoutesNames.Info.Cooperation,
        element: <CooperationAsync />,
        errorElement: <ErrorElement message='Cooperation Page' />,
      },
    ],
  },
]);
